import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, CheckboxControl, InputControl, SelectControl } from "./Common";
import { RadioButtonControl } from "./Common";
import { PatientDetails, Session, ValidatePatientForm } from "../models";
import {
  isDateValid,
  isDateStringAfterToday,
  isValidPostcode,
  ValidatePostcode,
  MapObjectToFormData,
  CallApi,
  METHOD,
  FormatDOB,
  isDateDOBFormat,
  sendGAEvent,
  maskEmail,
  maskMobile,
} from "../helpers";
import isEmpty from "lodash/isEmpty";
import SingleNameModal from "./SingleNameModal";
import {
  InitialValidatePatientForm,
  EXISTING_PATIENT,
  NEW_PATIENT,
} from "../constants";
import NoMatchingDetailsModal from "./NoMatchingDetailModal";

interface Props {
  newPatient: boolean;
  patient: PatientDetails;
  session: Session;
  setIsError: Function;
  nextClick: Function;
  restartAsNewPatientClick: Function;
}

const ValidatePatient = React.memo(
  ({
    newPatient,
    patient,
    session,
    setIsError,
    nextClick,
    restartAsNewPatientClick,
  }: Props) => {
    const [validatePatientForm, setValidatePatientForm] =
      useState<ValidatePatientForm>(InitialValidatePatientForm);
    const lNameLabel1 = "Patient's last name (as per Medicare if available)*";
    const lNameLabel2 = "Patient's name (as per Medicare if available)*";
    const [lNameLabel, setLnameLabel] = useState(
      validatePatientForm.singleName ? lNameLabel2 : lNameLabel1
    );
    const [focusName, setFocusName] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [displaySingleNameModal, setDisplaySingleNameModal] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [displayNoMatchingDetailsModal, setDisplayNoMatchingDetailsModal] =
      useState(false);

    const originalRequiredProps = [
      "fName",
      "lName",
      "dob",
      "gender",
      "address",
      "suburb",
      "pcode",
    ];

    const requiredProps = useRef([
      "fName",
      "lName",
      "dob",
      "gender",
      "address",
      "suburb",
      "pcode",
    ]);

    const genderOptions = [
      {
        id: "genderm",
        value: "Male",
        label: "Male",
      },
      {
        id: "genderf",
        value: "Female",
        label: "Female",
      },
      {
        id: "gendero",
        value: "Other",
        label: "Other",
      },
    ];

    const genderIdentityOptions = [
      {
        value: "",
        label: "",
      },
      {
        value: "Female",
        label: "Female",
      },
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Non-binary",
        label: "Non-binary",
      },
      {
        value: "Gender diverse",
        label: "Gender diverse",
      },
      {
        value: "Transgender",
        label: "Transgender",
      },
    ];

    const handleChange = (e: any): void => {
      const { name, value } = e.target;
      let newPatientDetails = {
        ...validatePatientForm,
        [name]: value,
      };
      // If the singleName check box was clicked clear fName and remove fName from requiredProps
      if (name === "singleName") {
        if (value) {
          newPatientDetails = {
            ...newPatientDetails,
            fName: "",
          };
          requiredProps.current = requiredProps.current.filter(
            (prop) => prop !== "fName"
          );
        } else {
          // Add fName back to required props
          if (!requiredProps.current.includes("fName")) {
            requiredProps.current = ["fName", ...requiredProps.current];
          }
        }
      } else if (name === "pcode") {
        if (!isValidPostcode(value)) {
          newPatientDetails = {
            ...newPatientDetails,
            isPostcodeAuthenticated: false,
          };
        } else if (
          value !== validatePatientForm.pcode ||
          !validatePatientForm.isPostcodeAuthenticated
        ) {
          callValidatePostcode(value);
        }
      }
      setValidatePatientForm(newPatientDetails);
      updateButtonIsActive(newPatientDetails);
      setFocusName("");
    };

    const handleSingleNameClick = (e: any): void => {
      setDisplaySingleNameModal(true);
    };

    const handleSingleNameModalClose = (e: any, reason: any) => {
      if (e.target?.value !== null) {
        // User clicked on a button
        setLnameLabel(e.target.value ? lNameLabel2 : lNameLabel1);
        handleChange(e);
      } else if (reason !== null) {
        // User clicked on backdrop or pressed escape so set singleNmae checkbox back to original value
        handleChange({
          name: "singleName",
          value: !validatePatientForm.singleName,
        });
      }
      setDisplaySingleNameModal(false);
    };

    const noMatchingDetailsModalClose = (e: any) => {
      const value = e.target.value;
      setDisplayNoMatchingDetailsModal(false);
      if (value === "newpatient") {
        // New patient
        restartAsNewPatientClick(validatePatientForm);
      }
    };

    const validDOB = useCallback((dob: string): boolean => {
      return isDateValid(dob) && !isDateStringAfterToday(dob);
    }, []);

    const validGender = useCallback(
      (gender: string): boolean => {
        return !newPatient || !isEmpty(gender);
      },
      [newPatient]
    );

    const validPostcode = useCallback(
      (pcode: string): boolean => {
        return !newPatient || isValidPostcode(pcode);
      },
      [newPatient]
    );

    const updateButtonIsActive = (
      patientDetails: ValidatePatientForm
    ): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(patientDetails));
      requiredProps.current.forEach((prop) => {
        if (isEmpty(map.get(prop))) {
          isButtonActive = false;
        }
      });
      if (
        !validDOB(patientDetails.dob) ||
        !validPostcode(patientDetails.pcode) ||
        !validGender(patientDetails.gender) ||
        (newPatient && !patientDetails.isPostcodeAuthenticated)
      ) {
        isButtonActive = false;
      }
      setIsButtonActive(isButtonActive);
    };

    const validate = async () => {
      if (isLoading) {
        return;
      }
      let errorsExist = false;
      setFocusName("");
      setValidationErrors([]);

      const validationErrors: string[] = [];
      const addError = (fieldName: string) => {
        if (!errorsExist) {
          setTimeout(() => {
            setFocusName(fieldName);
          });
        }
        validationErrors.push(fieldName);
        errorsExist = true;
      };
      const map = new Map(Object.entries(validatePatientForm));
      requiredProps.current.forEach((prop) => {
        if (isEmpty(map.get(prop))) {
          addError(prop);
        }
        if (prop === "dob" && !validDOB(map.get(prop))) {
          addError("dob");
        }

        if (prop === "gender" && !validGender(map.get(prop))) {
          addError(prop);
        }

        if (
          prop === "pcode" &&
          (!validPostcode(map.get(prop)) || !map.get("isPostcodeAuthenticated"))
        ) {
          addError(prop);
        }
      });

      if (errorsExist) {
        setTimeout(() => setValidationErrors(validationErrors));
      } else {
        await callValidatePatient();
      }
    };

    const callValidatePostcode = async (pcode: string) => {
      const result = await ValidatePostcode(pcode, setIsLoading);
      const newPatientDetails = {
        ...validatePatientForm,
        pcode,
        isPostcodeAuthenticated: result,
      };
      setValidatePatientForm(newPatientDetails);
      updateButtonIsActive(newPatientDetails);
      return;
    };

    const callValidatePatient = async () => {
      try {
        const body = MapObjectToFormData({
          ...validatePatientForm,
          cid: session.cid,
        });

        const response = await CallApi(
          "val_patient",
          METHOD.POST,
          body,
          null,
          setIsLoading,
          setIsError
        );

        if (session.patientType === EXISTING_PATIENT && !response.status) {
          // display modal
          setDisplayNoMatchingDetailsModal(true);
          return;
        } else {
          const patientDetails =
            session.patientType === NEW_PATIENT && !response.status
              ? validatePatientForm
              : response;
          const maskedEmail = maskEmail(patientDetails.email);
          const maskedMobile = maskMobile(patientDetails.mobileNo);

          let newPatientDetails = {
            ...patientDetails,
            singleName: validatePatientForm.singleName,
            maskedEmail,
            maskedMobile,
          };

          sendGAEvent({
            event: "patient_details",
            step: 4,
            step_name: "Patient Details",
            user_id: newPatientDetails.maskedEmail,
            extra: {
              gender: newPatientDetails.gender,
              birthday_year: newPatientDetails.dob.substring(6),
            }
          });
          nextClick(newPatientDetails);
        }
      } catch (e) {
        setIsError(true);
      }
    };

    useEffect(() => {
      if (newPatient) {
        requiredProps.current = originalRequiredProps;
      } else {
        requiredProps.current = originalRequiredProps.slice(0, 3);
      }

      if (!isEmpty(patient)) {
        let patientForm = {
          ...patient,
          dob:
            !isEmpty(patient.dob) && !isDateDOBFormat(patient.dob)
              ? FormatDOB(patient.dob)
              : patient.dob,
        };
        setValidatePatientForm(patientForm);
        if (patient.singleName) {
          requiredProps.current = requiredProps.current.filter(
            (prop) => prop !== "fName"
          );
        }
        updateButtonIsActive(patientForm);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPatient, patient]);

    return (
      <>
        <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
          <div className="cs-chkin-form-content step col-xs-11">
            <h3 className=" cs-chkin-form-step-heading">
              Great, we'll need some details
            </h3>
            {!validatePatientForm.singleName && (
              <InputControl
                type="text"
                label="Patient's first name (as per Medicare if available)*"
                name="fName"
                value={validatePatientForm.fName}
                onChange={handleChange}
                focus={focusName === "fName"}
                error={validationErrors.includes("fName")}
              />
            )}

            <InputControl
              type="text"
              label={lNameLabel}
              name="lName"
              value={validatePatientForm.lName}
              onChange={handleChange}
              focus={focusName === "lName"}
              error={validationErrors.includes("lName")}
            />

            <CheckboxControl
              id="singleName"
              name="singleName"
              label="The patient only has a first name or last name"
              checked={validatePatientForm.singleName}
              onClick={handleSingleNameClick}
            />

            <div className="pb-4">
              <InputControl
                type="date"
                label="Patient's date of birth (dd/mm/yyyy)*"
                id="dob"
                name="dob"
                maxlength={10}
                value={validatePatientForm.dob}
                onChange={handleChange}
                focus={focusName === "dob"}
                error={validationErrors.includes("dob")}
              />
            </div>

            {newPatient && (
              <>
                <RadioButtonControl
                  label="Patient's birth Sex"
                  name="gender"
                  value={validatePatientForm.gender}
                  options={genderOptions}
                  onChange={handleChange}
                  error={validationErrors.includes("gender")}
                />

                <SelectControl
                  id="gender_label"
                  name="gender_identity"
                  label="Patient's gender identity"
                  value={validatePatientForm.gender_identity}
                  options={genderIdentityOptions}
                  onChange={handleChange}
                />

                <InputControl
                  type="text"
                  label="Home Address*"
                  name="address"
                  value={validatePatientForm.address}
                  onChange={handleChange}
                  focus={focusName === "address"}
                  error={validationErrors.includes("address")}
                />

                <InputControl
                  type="text"
                  label="Suburb*"
                  name="suburb"
                  value={validatePatientForm.suburb}
                  onChange={handleChange}
                  focus={focusName === "suburb"}
                  error={validationErrors.includes("suburb")}
                />

                <div className="col-xs-7" style={{ padding: 0 }}>
                  <InputControl
                    type="text"
                    label="Postcode*"
                    name="pcode"
                    value={validatePatientForm.pcode}
                    maxlength={4}
                    onChange={handleChange}
                    triggerChangeOnKeyup={true}
                    focus={focusName === "pcode"}
                    error={validationErrors.includes("pcode")}
                  />
                </div>
                {validatePatientForm.isPostcodeAuthenticated && (
                  <div
                    id="valid_postcode"
                    className="col-xs-5 pt-4 text-success"
                  >
                    ✔
                  </div>
                )}
                {validatePatientForm.isPostcodeAuthenticated === false && (
                  <div
                    id="invalid_postcode"
                    className="col-xs-5 pt-4 text-danger"
                  >
                    x
                  </div>
                )}
              </>
            )}
            <div className="form-group">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
                title="Prev"
              ></button>
              <Button
                label="Next"
                handleClick={validate}
                isLoading={isLoading}
                isActive={isButtonActive}
              />
            </div>
          </div>
        </div>

        <SingleNameModal
          open={displaySingleNameModal}
          handleClose={handleSingleNameModalClose}
        />

        <NoMatchingDetailsModal
          open={displayNoMatchingDetailsModal}
          handleClose={noMatchingDetailsModalClose}
        />
      </>
    );
  }
);

export default ValidatePatient;
