import React from "react";
import { EXISTING_PATIENT, NEW_PATIENT } from "../constants";
import { sendGAEvent } from "../helpers";

type Props = {
  onChange: Function;
};

const RegistrationType = React.memo(({ onChange }: Props) => {
  const registrationTypeClick = (value: number) => {
    sendGAEvent({
      event: "patient_type", 
      step: 1, 
      step_name: "Patient Type", 
      extra: {
        new_patient: value === NEW_PATIENT,
      }
    });
    onChange(value);
  };

  return (
    <>
      <div className="cs-chkin-form-panel js-active" id="regTypePanel">
        <div className="cs-chkin-form-content col-xs-11">
          <p className="cs-chkin-form-step-heading mb-4" id="first_time">
            Is this your first time with <span className="bold">Our Sage</span>?
          </p>
          <button
            className="btn btn-block btn-selection np col-sm-12"
            data-np="1"
            id="new_patient"
            type="button"
            onClick={() => registrationTypeClick(NEW_PATIENT)}
          >
            New patient
            <img
              src="images/svg/cs_blue_tick.svg"
              className="ml-auto"
              alt="tick"
            />
          </button>
          <button
            className="btn btn-block btn-selection np col-sm-12 mb-0"
            data-np="2"
            id="existing_patient"
            type="button"
            onClick={() => registrationTypeClick(EXISTING_PATIENT)}
          >
            Existing patient
            <img
              src="images/svg/cs_blue_tick.svg"
              className="ml-auto"
              alt="tick"
            />
          </button>
          <div className="warning-box col-sm-12">
            <img src="images/svg/emergency_icon.svg" alt="emergency icon" />
            <span className="body-text ml-2">
              For a medical emergency, please call 000
            </span>
          </div>
        </div>
      </div>
    </>
  );
});

export default RegistrationType;
